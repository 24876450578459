import { version } from './version';

export const environment = {
  BASE_URL: 'https://dtemineiros.giss.com.br/PlatformaDTe',
  production: false,
  product: 'dte',
  client: 'eicon',
  i18n: 'pt_br',
  version: version,
  i18nPath: './assets/i18n/',
  assetsPath: './assets/',
  powerbi: '',
  schema: 'pbi_dte_mineiros',
  groupId: 'a3956a1e-7f52-4eb8-b57f-5fe44ec91a59',
  analiseGeral: 'fe23b2f1-2da3-402a-b201-a36a91d26945',
  analiseUsuarios: 'ba646a76-250d-463c-9ecd-000fd6a00630',
  analiseComunicados: '24c9e094-7188-4214-970e-b744921f6511',
  analiseProcurador: 'bc664d19-12d5-429a-98f3-838a78a89e89',
  analiseEventos: '109c103b-cd86-469a-ae10-4dec420c195b',
  analiseContribuintes: '387ffa62-3b4a-4ba4-9fea-3714a87dadb3'
};
